/* eslint-disable jsx-a11y/anchor-is-valid */
import {ChangeEvent, FC} from 'react'
import React, {useState} from 'react'
import './index.scss'
import {Link, Navigate} from 'react-router-dom'

import {GrantAccessWrapper} from './GrantAccessWrapper'
import axios from 'axios'
	
const PublisherWrapper: FC = () => {
  
  const [isShown, setIsShown] = useState(false);
  
  const [signupform, setSignupForm] = useState({
    name: '',
    email: '',
    phone_no: '',
    country: '',
    state: '',
    password: '',
    gender: '',
    birth_year: '',
  })
  

  const handleInput = (e: any) => {
    const {name, value} = e.target
    setSignupForm((prevProps) => ({
      ...prevProps,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: any) => {
    //console.log("eeeee---->",e)
    e.preventDefault()
    let check = true;
    if(e.target[0].value === ""){check = false;}
    else if(e.target[1].value === ""){check=false;}
    else if(e.target[2].value === ""){check=false;}
    else if(e.target[3].value === ""){check=false;}
    else if(e.target[4].value === ""){check=false;}
    else if(e.target[5].value === ""){check=false;}
    else{
      if(!check){
        return false;
      }
      else{
          setIsShown((current) => !current)
      }
    }
  }

  

  return (
    <>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div style={isShown ? {display: 'none'} : {display: 'block'}}>
            <form
              onSubmit={handleSubmit}
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            >
              {/* begin::Heading */}
              <div className='text-center mb-11'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Join WhatsAd </h1>
                {/* end::Title */}
              </div>
              {/* end::Heading */}

              <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Your Name</label>
                <input
                  type='text'
                  name='name'
                  value={signupform.name}
                  onChange={handleInput}
                  autoComplete='off'
                  placeholder='Your Name'
                  className='form-control bg-transparent'
                  required
                />
              </div>
              <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                <input
                  type='email'
                  name='email'
                  value={signupform.email}
                  onChange={handleInput}
                  autoComplete='off'
                  className='form-control bg-transparent'
                  placeholder='Email'
                  required
                />
              </div>
              <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label>
                <input
                  type='number'
                  name='phone_no'
                  value={signupform.phone_no}
                  onChange={handleInput}
                  autoComplete='off'
                  className='form-control bg-transparent'
                  placeholder='Phone Number'
                  required
                />
              </div>
              <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Country</label>
                <input
                  type='text'
                  name='country'
                  value={signupform.country}
                  onChange={handleInput}
                  autoComplete='off'
                  className='form-control bg-transparent'
                  placeholder='Country'
                  required
                />
              </div>
              <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>State</label>
                <input
                  type='text'
                  name='state'
                  value={signupform.state}
                  onChange={handleInput}
                  autoComplete='off'
                  className='form-control bg-transparent'
                  placeholder='State'
                  required
                />
              </div>
              <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                <input
                  type='password'
                  name='password'
                  value={signupform.password}
                  onChange={handleInput}
                  autoComplete='off'
                  className='form-control bg-transparent'
                  placeholder='Password'
                  required
                />
              </div>

              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  //onClick={handleClick}
                >
                  <span className='indicator-label'>Join</span>
                </button>
                <Link to='/auth/login'>
                  <button type='button' className='btn btn-lg btn-light-primary w-100 mb-5'>
                    Already have an account? Sign In
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>

      {isShown ? <AboutYou item={signupform} /> : null}
    </>
  )
}

export {PublisherWrapper}

function AboutYou(props: any) {
  const {item} = props
  const [isShown, setIsShown] = useState(false)
  const [errors, setErrors] = useState({})
  const [signupform, setSignupForm] = useState({
    gender: '',
    birth_year: '',
  })
  const [pdata, setData] = useState('')
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  //const handleClick = (event: any) => {
  //  setIsShown((current) => !current)
  //}

  const handleInput = (e: any) => {
    const {name, value} = e.target
    setSignupForm((prevProps) => ({
      ...prevProps,
      [name]: value,
    }))
  }
  const handleSubmit = async (e: any) => {
    
    e.preventDefault()
     
    let check = true;
    let gender = false;
    if(e.target[0].checked === true){gender = true;}
    if(e.target[1].checked === true){gender = true;}

    if(!gender){
      check=false;
    }
    else if(e.target[2].value === ""){
      check=false;
    }
    if(!check){
      return false;
    }
    else{
      setIsShown((current) => !current)
    }
    
    await axios
      .post(
        `${process.env.REACT_APP_API_URI}/publisher_register`,
        {},
        {params: {...item, ...signupform}}
      )
      .then((response) => {
        
        const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
        console.log('sub 111-->', response)
        const usercookies = JSON.stringify(response.data)
        setData(response.data.publisher.id)
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, usercookies)

        window.location.href="/grant-access"
      })
      .catch((err) => {

        console.log('ero', err.response);
        setIsShown((current) => !current)
        setErrors(err.response.data.errors)
      })
  }

  console.log('env---->', {pdata})

  return (
    <>
      <div className='row gy-5 gx-xl-8'>
        
        <div className='col-xl-12'>
          <div style={isShown ? {display: 'none'} : {display: 'block'}}>

          {errors && Object.keys(errors).map((key) => (<div className="alert alert-danger" role="alert">
            {errors[key][0]}
          </div>))}


            <form
              onSubmit={handleSubmit}
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            >
              <div className='text-center mb-11'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>About You</h1>
                <p className='mb-10 italic'>This helps us match you with the right types of ads.</p>
                {/* end::Title */}
              </div>

              <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Gender</label>
                <div className='row mb-10'>
                  <div className='col-xl-9'>
                    <div className='form-check form-check-custom form-check-solid mb-5'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='Male'
                        name='gender'
                        onChange={handleInput}
                      />
                      <label className='form-check-label'>Male</label>
                    </div>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='Female'
                        name='gender'
                        onChange={handleInput}
                        
                      />
                      <label className='form-check-label'>Female</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Birth Year</label>
                <input
                  className='form-control bg-transparent'
                  type='number'
                  min='1900'
                  max='2099'
                  step='1'
                  name='birth_year'
                  value={signupform.birth_year}
                  onChange={handleInput}
                  required
                />
              </div>
              
              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  
                >
                  <span className='indicator-label'>Next</span>
                </button>
              </div>
            </form>
          </div>
        </div>
       
      </div>
    </>
  )
}
