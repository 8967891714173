import clsx from 'clsx'
import * as React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
// import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {useNavigate} from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'

const itemClass = 'ms-1 ms-lg-3'
// const btnClass =
//   'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'


const Navbar = () => {
  const {currentUser} = useAuth()
  //console.log("current user 123--->",currentUser)
  const {config} = useLayout()
  const [value, setValue] = React.useState('addnew')
  let navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value)
    let value = event.target.value
    if (value === 'addnew') navigate('/auth/registration')
  }
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

<div className={clsx('app-navbar-item', itemClass)}>
<select
  name='add boat'
  className='form-select form-select-sm form-select-solid'
  onChange={handleChange}
>
{currentUser && (
    <>
      {currentUser.role === 'advertiser' && (
        <option value='bot 1'>Advertiser</option>
      )}
      {currentUser.role === 'publisher' && (
        <option value='bot 2'>Publisher</option>
      )}
      {(currentUser.role === 'advertiser' || currentUser.role === 'publisher') && (
        <>
          <option value='bot 1'>Advertiser</option>
          <option value='bot 2'>Publisher</option>
        </>
      )}
    </>
  )}
</select>

</div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
