/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import './index.scss'
import axios from 'axios';
import {useAuth} from '../../modules/auth'
const AdvertiserSetting: FC = () => {
  const token = useAuth().auth?.token ?? '';

  //---------------------------advertiser setting page add card details code start-----------------
  const [cardData, setCardData] = useState({
    card_holder_name: "",
    card_no: "",
    card_expire_date: "",
    cvv: "",
    zip: ""
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>)=>{
    const {name, value} = e.target;
    setCardData((prevProps)=>({
      ...prevProps,
      [name]: value
    }));
  } 

  const Addcard=async(e: React.FormEvent)=>{
    e.preventDefault(); // Prevent page reload
    try{
    const response=await axios.post(`${process.env.REACT_APP_API_URI}/add_advertiser_creative_card`,{...cardData, token});
    console.log("card response",response);
    }catch(error){console.log("card error",error)}
  }

//------------------------------advertiser setting page add card details code end ---------------------------

//---------------------------advertiser setting page change pwd code start-----------------
const [changePwd, setChangePWD] = useState({
  currentPassword: '',
  password: '',
  password_confirmation: ''
});

const handleInputPassword = (e: React.ChangeEvent<HTMLInputElement>)=>{
  const {name, value} = e.target;
  setChangePWD((prevProps)=>({
    ...prevProps,
    [name]: value
  }));
} 

const handlePassword=async(e: React.FormEvent)=>{
  e.preventDefault(); // Prevent page reload
  try{
  const response=await axios.post(`${process.env.REACT_APP_API_URI}/advisor_change_password `,{...changePwd, token});
  console.log("change pwd response",response);
  }catch(error){console.log("change pwd error",error)}
}

//------------------------------advertiser setting page change pwd code end ---------------------------

//-----------------------------advertiser card details get start-----------------
useEffect(() => {
  CardRecords();
}, []);

const CardRecords=async()=>{
  try{
const response=await axios.get(`${process.env.REACT_APP_API_URI}/get_advertiser_creative_card`, {params: token})
  console.log("response of card records--->",response)
}
catch(error){
  console.log("error of card details--->", error)
}
}
//-----------------------------advertiser card details get end-------------------

//-----------------------------delete card details code start--------------------
//const RemoveCard=async()=>{
//  if (token) {
//    try{
//      const response=await axios.delete(`${process.env.REACT_APP_API_URI}/delete_advertiser_creative_card`, {params: token})
//      console.log(response)
//    }
//    catch(error){
//      console.log(error)
//    }
//  } else {
//    console.error('No token available for the request.');
//  }
//}
//-----------------------------delete card details code end----------------------


  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Advertiser Settings</h3>
      </div>
      <div className='flex-row-fluid ml-lg-8 mb-5'>
        <form className='card card-custom' onSubmit={handlePassword}>
          <div className='card-header py-3'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Change Password</h3>
            </div>
            <div className='card-toolbar'>
              <button type='submit' className='btn btn-primary mr-2'>
                Save Changes
              </button>
              <a className='btn btn-secondary'>Cancel</a>
            </div>
          </div>
          <div className='form'>
            <div className='card-body'>
              <div className='form-group row mb-5'>
                <label className='col-xl-3 col-lg-3 form-label fw-bolder text-dark fs-6'>
                  Current Password
                </label>
                <div className='col-lg-9 col-xl-6'>
                  <input
                    type='password'
                    placeholder='Current Password'
                    className='form-control bg-transparent mb-2'
                    name='currentPassword'
                    value={changePwd.currentPassword}
                    onChange={handleInputPassword}
                  />
                  <a href='#' className='text-sm font-weight-bold'>
                    Forgot password ?
                  </a>
                </div>
              </div>
              <div className='form-group row mb-5'>
                <label className='col-xl-3 col-lg-3 form-label fw-bolder text-dark fs-6'>
                  New Password
                </label>
                <div className='col-lg-9 col-xl-6'>
                  <input
                    type='password'
                    placeholder='New Password'
                    className='form-control bg-transparent'
                    name='password'
                    value={changePwd.password}
                    onChange={handleInputPassword}
                  />
                </div>
              </div>
              <div className='form-group row mb-5'>
                <label className='col-xl-3 col-lg-3 form-label fw-bolder text-dark fs-6'>
                  Verify Password
                </label>
                <div className='col-lg-9 col-xl-6'>
                  <input
                    type='password'
                    placeholder='Verify Password'
                    className='form-control bg-transparent'
                    name='password_confirmation'
                    value={changePwd.password_confirmation}
                    onChange={handleInputPassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='flex-row-fluid ml-lg-8 mb-5'>
        <form className='card card-custom' onSubmit={Addcard}>
          <div className='card-header py-3'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Payment Method</h3>
            </div>
            <div className='card-toolbar'>
              <button type='submit' className='btn btn-primary mr-2'>
                Save Changes
              </button>
              <a className='btn btn-secondary'>Cancel</a>
            </div>
          </div>
          <div className='form'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-3'></div>
                <div className='col-lg-6'>
                  <div className='row gy-5 gx-xl-8'>
                    <div className='col-xs-12'>
                      <div className='fv-row'>
                        <input
                          type='text'
                          autoComplete='off'
                          className='form-control bg-transparent'
                          placeholder='Cardholder Name'
                          name='card_holder_name'
                          value={cardData.card_holder_name}
                          onChange={handleInput}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-xs-12'>
                      <div className='fv-row'>
                        <input
                          type='text'
                          autoComplete='off'
                          className='form-control bg-transparent'
                          placeholder='Card Number'
                          name='card_no'
                          value={cardData.card_no}
                          onChange={handleInput}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='fv-row'>
                        <input
                          type='text'
                          autoComplete='off'
                          className='form-control bg-transparent'
                          placeholder='MM / YY'
                          name='card_expire_date'
                          value={cardData.card_expire_date}
                          onChange={handleInput}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='fv-row'>
                        <input
                          type='text'
                          autoComplete='off'
                          className='form-control bg-transparent'
                          placeholder='CVV'
                          name='cvv'
                          value={cardData.cvv}
                          onChange={handleInput}
                          required
                        />
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='fv-row'>
                        <input
                          type='text'
                          autoComplete='off'
                          className='form-control bg-transparent'
                          placeholder='ZIP'
                          name='zip'
                          value={cardData.zip}
                          onChange={handleInput}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3'></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export {AdvertiserSetting}