import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PublisherWrapper} from '../pages/publisher/PublisherWrapperWrapper'
import {AdMarketWrapper} from '../pages/admarket/AdMarketWrapper'
import {YourAdsWrapper} from '../pages/yourads/YourAdsWrapper'
import {PayoutsWrapper} from '../pages/publisher/payouts'
import {AdvertiserWrapper} from '../pages/advertiser/AdvertiserWrapper'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {AdvertiserSetting} from '../pages/advertiser/AdvertiserSetting'
import {PaymentsSetting} from '../pages/publisher/paymentSetting'
const PrivateRoutes = () => {
  const {currentUser} = useAuth()
  //console.log("private routes---->",currentUser)
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Groups after success login/registartion */}

        {currentUser?.role === 'advertiser' ? (
          <>
            <Route path='auth/*' element={<Navigate to='/creatives' />} />
          </>
          ) : (
            <>
              <Route path='auth/*' element={<Navigate to='/your-ads' />} />
            </>
          )}
       
        {/* Pages */}

        <Route path='publisher' element={<PublisherWrapper />} />
        <Route path='your-ads' element={<YourAdsWrapper />} />
        <Route path='payouts' element={<PayoutsWrapper />} />
        <Route path='payments-setting' element={<PaymentsSetting />} />
        <Route path='creatives' element={<AdvertiserWrapper />} />
        <Route path='/advertiser/settings' element={<AdvertiserSetting />} />
        {/* Lazy Modules */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
