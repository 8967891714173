/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import axios from 'axios';
import ShareButton from './ShareButton';

interface MyData {
  token: string;
}

interface Record {
  id: number;
  name: string;
  images: string;
  details: string;
  price: string;
  deadline: string;
  limit: string;
  category: string;
  creatives: string;
  link: string;
}
const YourAdsWrapper: FC = () => {
  const [records, setRecords] = useState<Record[]>([]);
  const [postedrecords, setPostedRecords] = useState<Record[]>([]);

  
  useEffect(() => {
    fetchRecords();
    PostedfetchRecords();
  }, []);

  const fetchRecords = async() => {
  const storedData = localStorage.getItem('kt-auth-react-v');
  const parsedData: MyData | null = storedData ? JSON.parse(storedData) : null;
  //console.log("abcd--->",parsedData)
  if (!parsedData) {
    return;
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URI}/get_publisher_campaign`, {
      params: {
        token: parsedData.token // Pass token in the request parameters
      }
    });
    //console.log("get particular campaign--->", response.data.publishers_campaign);
    //setRecords(response.data.publishers_campaign)
    
    var a: any;

    a = {
          "id": 27,
          "name": "Help orphan bride",
          "details": "adsdas",
          "images": "https://devapi.whatsad.io/creative/27.jpeg",
          "creatives": `*HEARTBREAKING TRAGEDY:* 

Rabbi Menachem Braun Z'L, son of Rabbi Avraham Braun who was the former Menahel of Ohr Someach, tragically left this world at the tender age of 49, leaving behind a grieving wife and four children  *Pre-existing medical conditions presented obstacles towards obtaining life insurance which means this campaign is crucial to supporting the Braun family as they grapple with this challenge.*

*Please donate:*`,
          "price": "$5",
          "deadline": "",
          "limit": "",
          "category": "",
          "link": "https://thechesedfund.com/ay/the-rabbi-menachem-braun-z-l-memorial-fund?aff=wa",
          "updated_at": "2023-08-24T11:41:27.000000Z",
          "created_at": "2023-08-24T11:41:27.000000Z"
    };


    setRecords([a]);
  } catch (error) {
    console.error('API Error:', error);
  }
  }

  const PostedfetchRecords = async() => {
    const storedData = localStorage.getItem('kt-auth-react-v');
    const parsedData: MyData | null = storedData ? JSON.parse(storedData) : null;
    //console.log("abcd--->",parsedData)
    if (!parsedData) {
      return;
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URI}/added_publisher_campaign`, {
        params: {
          token: parsedData.token // Pass token in the request parameters
        }
      });
      //console.log("get posted ads-->", response.data.publishers_campaign);
      setPostedRecords(response.data.publishers_campaign)
    } catch (error) {
      console.error('API Error:', error);
    }
    }

  console.log("rrecoo 111-->",records)
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Your Ads</h3>

        <div className='d-flex flex-wrap my-2'>
          <div className='me-5 d-flex align-items-center'>
            <span className='fs-6 text-black-400 fw-bold me-4'>Sort</span>
            <select
              name='group'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='All'
            >
              <option value='All'>All</option>
            </select>
          </div>
          <input
            type='search'
            placeholder='search'
            className='form-control form-select-sm w-125px'
          />
        </div>
      </div>
      <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
        <li className='nav-item'>
          <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
            Pending Ads
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
            Posted Ads
          </a>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
          <div className='row gy-5 gx-xl-8'>
            <div className='col-xl-12'>
              <div className={`card card-xxl-stretch mb-xl-3`}>
                <div className='card-body pt-2 contacts'>
                  <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                    <div className='card-body py-3'>
                      <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-150px'>Campaign Name</th>
                              <th className='min-w-150px'>Details</th>
                              <th className='min-w-150px'>Creatives</th>
                              <th className='min-w-150px text-end'></th>
                            </tr>
                          </thead>

                          <tbody>
                          {records.map((record) => (
                            <tr key={record.id}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-dark fw-bold text-hover-primary fs-6'>
                                    {record.name}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-dark fw-bold text-hover-primary fs-6'>
                                      Prices: {record.price}
                                      <br />
                                      Deadline: {record.deadline}
                                      <br />
                                      Limit: {record.limit}
                                      <br />
                                      Category: {record.category}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                  <div className='d-flex align-items-center'>
                                  <img src={record.images} alt='' style={{ maxWidth: '100px', marginRight: '10px' }} />
                                  <span className='text-dark fw-bold text-hover-primary fs-6'>
                                    {record.creatives}
                                    <br /><br/>
                                    {record.link}
                                  </span>
                                </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  {/*<button type='button' className='btn btn-primary fw-bold px-3'>*/}
                                  <ShareButton record={record} title="Check this out!"/>
                                  {/*</button>*/}
                                </div>
                              </td>
                            </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
          <div className='row gy-5 gx-xl-8'>
            <div className='col-xl-12'>
              <div className={`card card-xxl-stretch mb-xl-3`}>
                <div className='card-body pt-2 contacts'>
                  <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                    <div className='card-body py-3'>
                      <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-150px'>Campaign Name</th>
                              <th className='min-w-150px'>Details</th>
                              <th className='min-w-150px'>Creatives</th>
                              <th className='min-w-150px text-end'></th>
                            </tr>
                          </thead>

                          <tbody>
                          {postedrecords.map((record) => (
                            <tr key={record.id}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-dark fw-bold text-hover-primary fs-6'>
                                    {record.name}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-dark fw-bold text-hover-primary fs-6'>
                                      Prices: {record.price}
                                      <br />
                                      Deadline: {record.deadline}
                                      <br />
                                      Limit: {record.limit}
                                      <br />
                                      Category: {record.category}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <div className='d-flex align-items-center'>
                                    <img src={record.images} alt='' style={{ maxWidth: '100px', marginRight: '10px' }} />
                                      <span className='text-dark fw-bold text-hover-primary fs-6'>
                                      {record.creatives}
                                        <br /><br/>
                                        {record.link}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  {/*<button type='button' className='btn btn-primary fw-bold px-3'>
                                    Share Ad
                                  </button>*/}
                                  <ShareButton record={record} title="Check this out!" />
                                </div>
                              </td>
                            </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {YourAdsWrapper}
