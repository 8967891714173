/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import Alert from '@mui/material/Alert'

const PaymentsSetting: FC = () => {
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Payments</h3>
      </div>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className={`card card-xxl-stretch mb-xl-3`}>
            <div className='card-body pt-2 contacts'>
              <div className={`card card-xxl-stretch`}>
                <div className='card-body py-3'>
                  <div className='beforeConnectStripe'>
                    <p>Connect with Stripe to receive payouts</p>
                    <button type='button' className='btn btn-primary fw-bold px-3'>
                      <img
                        src='/media/icons/duotune/general/stripe.png'
                        style={{maxWidth: '60px'}}
                      />{' '}
                      Connect
                    </button>
                  </div>
                  <div className='afterConnectStripe'>
                    <Alert severity='success' className='mb-5' style={{fontSize: '15px'}}>
                      Connected to Stripe
                    </Alert>
                    <button type='button' className='btn btn-light-danger font-weight-bold mr-2'>
                      Disconnect
                    </button>
                    <button type='button' className='btn btn-light-danger font-weight-bold mr-2'>
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {PaymentsSetting}
