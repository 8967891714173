import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'
import axios from 'axios'

//interface MyData {
//  token: string;
//}

export function Logout() {
  
  const { logout } = useAuth()
  console.log("logout--->", useAuth())
  useEffect(() => {
    logout()
    document.location.reload()
  }, [logout])



  //useEffect(() => {
  //  const logoutAndRedirect = async () => {
  //    const storedData = localStorage.getItem('kt-auth-react-v');
  //    const parsedData: MyData | null = storedData ? JSON.parse(storedData) : null;

  //    if (parsedData !== null) {
  //      console.log(parsedData.token);
  //      console.log('hello token-->', parsedData.token);

  //      try {
  //        const response = await axios.post(`${process.env.REACT_APP_API_URI}/logout`, {
  //          token: parsedData.token,
  //        });
  //        console.log('publisher logout--->', response);
  //      } catch (error) {
  //        console.log(error);
  //      }
  //    } else {
  //      console.log('No data found in localStorage');
  //    }

  //    logout();
  //    document.location.reload();
  //  };

  //  logoutAndRedirect();
  //}, [logout]);

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
