/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import axios from 'axios';
import {Logout, AuthPage, useAuth} from '../../modules/auth'



const PayoutsWrapper: FC = () => {
    const {currentUser} = useAuth();

  var ID: any = currentUser?.id.toString();
  var view_count: any;

  const [views, setViews] = useState(0);
  const [clicks, setClicks] = useState(0);
  const [payout, setPayout] = useState(0);

  useEffect(() => {
    const response = axios.post(`https://io.whatsad.io/get-ad`, Object.assign({}, {
      user_id: ID,
      ads: [27],
    })).then((r) => {
      var view_count = r.data.reduce((count, o) => {
        return count + o.seen_by_count;
      },0);
      var payout_count = r.data.reduce((count, o) => {
        return count + 5;
      },0);

      setViews(view_count);
      setPayout(payout_count);
    });
  }, [ID]);

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Payout</h3>

        <div className='d-flex flex-wrap my-2'>
          <div className='me-4 d-flex align-items-center'>
            <select name='status' className='form-select form-select-sm form-select-white w-125px'>
              <option selected>This Month</option>
              <option>Last Month</option>
              <option>Last 90 Days</option>
              <option>This Year</option>
            </select>
          </div>
        </div>
      </div>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-4 mb-md-5 mb-xl-10'>
          <div className={`card card-flush `}>
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <div className='card-title d-flex flex-column'>
                  <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{views}</span>
                  <span className='text-gray-400 pt-1 fw-semibold fs-6'>Views</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-4 mb-md-5 mb-xl-10'>
          <div className={`card card-flush `}>
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <div className='card-title d-flex flex-column'>
                  <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>0</span>
                  <span className='text-gray-400 pt-1 fw-semibold fs-6'>Clicks</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4 mb-md-5 mb-xl-10'>
          <div className={`card card-flush `}>
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <div className='card-title d-flex flex-column'>
                  <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>${payout}</span>
                  <span className='text-gray-400 pt-1 fw-semibold fs-6'>Earnings</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <h3 className='fw-bolder my-2 mb-10'>Payout History</h3>
          <div className={`card card-xxl-stretch mb-xl-3`}>
            <div className='card-body pt-2 contacts pb-0'>
              <div className={`card card-xxl-stretch`}>
                <div className='card-body py-3 pb-0'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-150px'>Date</th>
                          <th className='min-w-150px'>Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {/*<tr>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary fs-6'>
                              13/04/23
                            </span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary fs-6'>&3 </span>
                          </td>
                        </tr>*/}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {PayoutsWrapper}
