/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import axios from 'axios';
import {useAuth} from '../../modules/auth'

interface MyData {
  token: string;
}

interface Record {
  id: number;
  name: string;
  creative: string;
  impressions: number;
  targetting: string;
}

const AdvertiserWrapper: FC = () => {
  const [records, setRecords] = useState<Record[]>([]);
  const token = useAuth().auth?.token ?? ''; // Use an empty string as the default value
  
  
  const handleSubmit = async (e:any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", e.target.name.value);
    formData.append("creative", e.target.creative.value);
    formData.append("impressions", e.target.impressions.value);
    formData.append("targetting", e.target.targetting.value);
    //formData.append("image", e.target.image.value);
    formData.append("link", e.target.link.value);
    formData.append("card_holder_name", e.target.card_holder_name.value);
    formData.append("card_no", e.target.card_no.value);
    formData.append("card_expire_date", e.target.card_expire_date.value);
    formData.append("cvv", e.target.cvv.value);
    formData.append("zip", e.target.zip.value);
    formData.append("token", token);
    const imageInput = e.currentTarget.querySelector('[name="image"]') as HTMLInputElement;
    if (imageInput?.files?.length) {
      formData.append('image', imageInput.files[0]);
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URI}/added_advertiser_creative`, formData);
      console.log("response create ad new-->", response);
    } catch (error) {
      console.log("error create ad new -->", error);
    }
  }

  
  useEffect(() => {
    fetchRecords();
  }, []);

  const fetchRecords = async() => {
  const storedData = localStorage.getItem('kt-auth-react-v');
  const parsedData: MyData | null = storedData ? JSON.parse(storedData) : null;
  //console.log("abcd--->",parsedData)
  if (!parsedData) {
    return;
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URI}/get_advertiser_creative`, {
      params: {
        token: parsedData.token // Pass token in the request parameters
      }
    });
    //console.log("aaaaaaa-->",response)
    setRecords(response.data.creative)
  } catch (error) {
    console.error('API Error:', error);
  }
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Creatives</h3>

        <div className='d-flex flex-wrap my-2'>
          <a
            href='#'
            className='btn btn-primary mr-3'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
          >
            <i className='flaticon2-box-1'></i> New Ad
          </a>
        </div>
      </div>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className={`card card-xxl-stretch mb-xl-3`}>
            <div className='card-body pt-2 contacts'>
              <div className={`card card-xxl-stretch`}>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-150px'>Name</th>
                          <th className='min-w-150px'>Creative</th>
                          <th className='min-w-150px'>Impressions</th>
                          <th className='min-w-150px text-end'>Targetting</th>
                        </tr>
                      </thead>
                      <tbody>
                      {records.map((record) => (
                        <tr key={record.id}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold text-hover-primary fs-6'>
                                  {record.name}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-dark fw-bold text-hover-primary fs-6'>
                                  {record.creative}
                                  <span className='text-dark fw-bold text-hover-primary fs-6 mr-3'>
                                    <button
                                      type='button'
                                      className='btn btn-primary fw-bold px-3'
                                      style={{marginLeft: '15px'}}
                                    >
                                      Edit
                                    </button>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <div className='d-flex align-items-center'>
                                  <span className='text-dark fw-bold text-hover-primary fs-6'>
                                    {record.impressions}
                                    <button
                                      type='button'
                                      className='btn btn-primary fw-bold px-3'
                                      style={{marginLeft: '15px'}}
                                    >
                                      Add
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <span className='text-dark fw-bold text-hover-primary fs-6'>
                                {record.targetting}
                              </span>
                            </div>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Create Ad</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <form onSubmit={handleSubmit}>
                <div className='row mb-5'>
                  <div className='col-xl-12'>
                    <div className='fv-row '>
                      <label className='form-label fw-bolder text-dark fs-6'>Campaign Name</label>
                      <input
                        name='name'
                        type='text'
                        autoComplete='off'
                        className='form-control bg-transparent'
                        required 
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-5'>
                  <div className='col-xl-12'>
                    <div className='fv-row'>
                      <label className='form-label fw-bolder text-dark fs-6'>Impressions</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xl-9'>
                      <input
                        name='impressions'
                        type='text'
                        autoComplete='off'
                        className='form-control bg-transparent'
                        required
                      />
                    </div>
                    <div className='col-xl-3'>
                      {/*<p>= $125</p>*/}
                    </div>
                  </div>
                </div>
                <div className='row gy-5 gx-xl-8'>
                  <div className='col-xl-12'>
                    <div className='fv-row'>
                      <label className='form-label fw-bolder text-dark fs-6'>Payment Method</label>
                      <input
                        type='text'
                        autoComplete='off'
                        className='form-control bg-transparent'
                        placeholder='Cardholder Name'
                        name='card_holder_name'
                        required
                      />
                    </div>
                  </div>
                  <div className='col-xl-12'>
                    <div className='fv-row'>
                      <input
                        type='text'
                        autoComplete='off'
                        className='form-control bg-transparent'
                        placeholder='Card Number'
                        name='card_no'
                        required
                      />
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='fv-row'>
                      <input
                        type='text'
                        autoComplete='off'
                        className='form-control bg-transparent'
                        placeholder='MM / YY'
                        name='card_expire_date'
                        required
                      />
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='fv-row'>
                      <input
                        type='text'
                        autoComplete='off'
                        className='form-control bg-transparent'
                        placeholder='CVV'
                        name='cvv'
                        required
                      />
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='fv-row'>
                      <input
                        type='text'
                        autoComplete='off'
                        className='form-control bg-transparent'
                        placeholder='ZIP'
                        name='zip'
                        required
                      />
                    </div>
                  </div>
                  <div className='col-xl-12'>
                    <div className='fv-row'>
                      <label className='form-label fw-bolder text-dark fs-6'>Targeting</label>
                      <select className='form-control bg-transparent' name='targetting'>
                        <option disabled>Select Audience</option>
                        <option value="teens">Teens</option>
                        <option value="consumer">Consumer</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-xl-12'>
                    <div className='fv-row'>
                      <label className='form-label fw-bolder text-dark fs-6'>Creatives</label>
                      <input
                        type='file'
                        name='image'
                        className='form-control bg-transparent mb-5'
                        placeholder='Upload Image'
                        required
                      />

                      <textarea
                        className='form-control bg-transparent mb-5'
                        placeholder='Ad Content' name='creative'
                      ></textarea>
                      <input
                        type='text'
                        name='link'
                        autoComplete='off'
                        className='form-control bg-transparent'
                        placeholder='URL'
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
              <button type='submit' className='btn btn-primary' data-bs-dismiss='modal' 
               // Call the handleSubmit function
              >
                Create Ad
              </button>
            </div>
              </form>
            </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export {AdvertiserWrapper}
