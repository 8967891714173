/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import './SidebarMenuMain.scss'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const {currentUser} = useAuth()
  return (
    <>
      {currentUser ? (
        <>
          {currentUser.role === 'advertiser' ? (
            <>
              <SidebarMenuItem
                to='/creatives'
                icon='/media/icons/duotune/general/gen056.svg'
                title='Creatives'
                fontIcon='bi-layers'
              />
              <SidebarMenuItem
                to='/advertiser/settings'
                icon='/media/icons/duotune/general/gear-solid.svg'
                title='Settings'
                fontIcon='bi-layers'
              />
            </>
          ) : (
            <>
              {/*<SidebarMenuItem
                to='/publisher'
                icon='/media/icons/duotune/general/gen032.svg'
                title='Publisher'
                fontIcon='bi-layers'
              />*/}
              {/*<SidebarMenuItem
                to='/ad-market'
                icon='/media/icons/duotune/general/gen056.svg'
                title='Ad Market'
                fontIcon='bi-layers'
              />*/}
              <SidebarMenuItem
                to='/your-ads'
                icon='/media/icons/duotune/general/square-plus-solid.svg'
                title='Your Ads'
                fontIcon='bi-layers'
              />
              <SidebarMenuItem
                to='/payouts'
                icon='/media/icons/duotune/general/square-plus-solid.svg'
                title='Payouts'
                fontIcon='bi-layers'
              />
              <SidebarMenuItem
                to='/payments-setting'
                icon='/media/icons/duotune/general/gear-solid.svg'
                title='Settings'
                fontIcon='bi-layers'
              />
            </>
          )}
        </>
      ) : null}
    </>
  )
}

export {SidebarMenuMain}
