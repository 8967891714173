/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
//import {useFormik} from 'formik';
import React, {useState} from 'react';
//import * as Yup from 'yup'
import clsx from 'clsx'
//import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
//import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import axios from 'axios';

export function Registration() {
  const [loading, setLoading] = useState(false)
  //const {saveAuth, setCurrentUser} = useAuth()
  const [rdata, setData] = useState(null);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [cnfpassword, setCNFPassword] = useState('');
  //const [isLoggedin, setIsLoggedin] = useState(false);
  const [registerform, setRegisterForm] = useState({
    name: "",
    email: "",
    phone_no: "",
    password: "",
    password_confirmation :""
  });

  const handleInput = (e: any)=>{
    const {name, value} = e.target;
    setRegisterForm((prevProps)=>({
      ...prevProps,
      [name]: value
    }));
  }

  //registration advertiser
  const handleSubmit=async(e:any)=>{
    e.preventDefault();
    await axios.post(`${process.env.REACT_APP_API_URI}/register_user`,{},{params: registerform})
    .then(response=>{
        const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
      console.log("register 111-->",response)
      const usercookies = JSON.stringify(response.data)
      setData(response.data);
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, usercookies)
      window.location.href="/creatives"

    })
    .catch(err=>{
      console.log("ero",err.response.data.errors);
      setError(err.response.data.errors);
      setEmail(err.response.data.errors.email)
      setName(err.response.data.errors.name)
      setPhone(err.response.data.errors.phone_no)
      setPassword(err.response.data.errors.password);
      setCNFPassword(err.response.data.errors.password_confirmation)
    })
  }
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Join WhatsAd </h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {/*{rdata?.rdata.map((ds) => (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {ds.message}
            </div>
        </div>
      ))}*/}

      {/* begin::Form group yourname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Your Name</label>
        <input
          placeholder='Your name'
          name='name'
          value={registerform.name}
          onChange={handleInput}
          type='text'
          autoComplete='off'
          //{...formik.getFieldProps('yourname')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': name && name},
            {
              'is-valid': name && !name,
            }
          )}
        />
       
        {/*{formik.touched.yourname && formik.errors.yourname && (*/}
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                {name}
                </span>
            </div>
          </div>
        {/*)}*/}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          name='email'
          value={registerform.email}
          onChange={handleInput}
          type='email'
          autoComplete='off'
          //{...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': email && email},
            {
              'is-valid': email && !email,
            }
          )}
        />
        
        {/*{formik.touched.email && formik.errors.email && (*/}
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                {email}
                </span>
            </div>
          </div>
        {/*)}*/}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group phonenumber */}
        <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label>
        <input
          placeholder='Phone Number'
          name='phone_no'
          value={registerform.phone_no}
          onChange={handleInput}
          type='number'
          autoComplete='off'
          //{...formik.getFieldProps('phonenumber')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': phone && phone,
            },
            {
              'is-valid': phone && !phone,
            }
          )}
        />
       
        {/*{formik.touched.phonenumber && formik.errors.phonenumber && (*/}
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                {phone}
                </span>
            </div>
          </div>
        {/*)}*/}
        {/* end::Form group */}
      </div>
      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              name='password'
              value={registerform.password}
              onChange={handleInput}
              placeholder='Password'
              autoComplete='off'
              //{...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': password && password,
                },
                {
                  'is-valid': password && !password,
                }
              )}
            />
            {/*{formik.touched.password && formik.errors.password && (*/}
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    {password}
                    </span>
                </div>
              </div>
            {/*)}*/}
          </div>
          {/* begin::Meter */}
          {/*<div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>*/}
          {/* end::Meter */}
        </div>
        {/*<div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>*/}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          name='password_confirmation'
          value={registerform.password_confirmation}
          onChange={handleInput}
          placeholder='Password confirmation'
          autoComplete='off'
          //{...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': cnfpassword && cnfpassword,
            },
            {
              'is-valid': cnfpassword && !cnfpassword,
            }
          )}
        />
        {/*{formik.touched.changepassword && formik.errors.changepassword && (*/}
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
              {cnfpassword}
                </span>
            </div>
          </div>
        {/*)}*/}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          //disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Join</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
         )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Already have an account? Sign In
          </button>
        </Link>
      </div>
      
      {/* end::Form group */}
    </form>
    
  )
}
