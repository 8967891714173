import React from 'react';
import axios from 'axios';

import ShareButtonIcon from './ShareButtonIcon'; // You can create this component to display the share icon
import {useAuth} from '../../../app/modules/auth'

interface ShareButtonProps {
  record: any; // The URL you want to share
  title?: string; // Optional title for the share
  url?: string; // Optional title for the share
}

const ShareButton: React.FC<ShareButtonProps> = ({ record, title, url }) => {
  const {currentUser} = useAuth()

  const shareContent = {
    record: record,
  };
  const shareHandler = async () => {
  alert('We sent you a whatsapp message, please share the message to your status :)')
    const response = await axios.post(`https://io.whatsad.io/share/ad`, Object.assign({}, record, {
      user_id: currentUser.id
    }));

  };

  return (
    <div onClick={shareHandler}>
      <ShareButtonIcon />
    </div>
  );
};

export default ShareButton;
